import { useRef, useLayoutEffect, useState } from 'react';

const useGetSvgWidthOnResize = () => {
  const elementRef = useRef(null);
  const [width, setWidth] = useState(null);
  const [labelWidth, setLabelWidth] = useState(null);
  const updateHeatMapWidth = () => {
    if (elementRef.current) {
      const svgElement = elementRef.current.querySelector('svg');

      if (svgElement) {
        const gElement = svgElement.querySelector('g');
        if (gElement) {
          setTimeout(() => {
            const childGs = Array.from(gElement.children).filter(
              (element) => element.tagName === 'g',
            );
            // axis width
            const newWidth = childGs[0].getBoundingClientRect().width;
            setWidth(newWidth);
            // label width
            const newLabelWidth = childGs[1].getBoundingClientRect().width;
            setLabelWidth(newLabelWidth);
          }, 1000);
        }
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateHeatMapWidth);
    return () => {
      window.removeEventListener('resize', updateHeatMapWidth);
    };
  }, []);

  return [elementRef, width, labelWidth, updateHeatMapWidth];
};

export default useGetSvgWidthOnResize;
