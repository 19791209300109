import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { FdAccordion, FdTypography, FdIconsV5 } from '@fifthdomain/fe-shared';

const CatalogAccordion = () => {
  const { ShoppingCart } = FdIconsV5;
  const theme = useTheme();
  return (
    <Box mt={2}>
      <FdAccordion
        variant="info"
        startAdornmentIcon={() => <ShoppingCart />}
        summary={() => (
          <FdTypography variant="subtitle1">
            What is this store for and what can you do with these challenges?
          </FdTypography>
        )}
        endAdornment
        showToggleButton={false}
        content={() => (
          <Box>
            <FdTypography variant="body2">
              <span
                style={{
                  color: theme.palette.common.black,
                }}
              >
                Welcome to the FifthDomain Challenges Store! Here, you will find
                all available challenges for lease. Leased challenges will be
                accessible in your organisation&apos;s Manage Challenges screen
                and reflected in your billing.Challenges can be viewed, utilised
                in competitions and assessments, and have tags managed by your
                admin/managers.
                <br />
                <br />
                FifthDomain owns all intellectual property rights in challenge
                content and assessments.
              </span>
            </FdTypography>
          </Box>
        )}
        open
      />
    </Box>
  );
};

export default CatalogAccordion;
