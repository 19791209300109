import React, { useEffect, useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  AuthContext,
  useSnapshot,
  globalStore,
  FdProgress,
} from '@fifthdomain/fe-shared';

const ContentCatalogueRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const globalSnap = useSnapshot(globalStore);
  const type = globalSnap.userType;

  const history = useHistory();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (userData) {
        setUser(userData);
      })
      .catch((err) => {
        console.log('error: ', err);
        history.push('/');
      });
  }, []);

  if (!type) {
    return <FdProgress />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // only ADMINS are allowed to view content lease catalogue
        if (user && type === 'ADMIN') {
          return <Component {...rest} {...props} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
};

ContentCatalogueRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ContentCatalogueRoute;
