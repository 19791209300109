import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { FdCard, FdTextField } from '@fifthdomain/fe-shared';

const Details = ({ editMode, templateType }) => {
  const { control } = useFormContext();
  const Wrapper = editMode ? Box : FdCard;
  return (
    <Wrapper
      heading="Details"
      subHeading={`Enter details for this ${templateType} template.`}
    >
      <Box display="flex" flexDirection="column">
        <Controller
          control={control}
          name="name"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id="name"
                label="Name"
                required
                fullWidth
                error={error}
                helperText={
                  error
                    ? error.message
                    : `Your ${templateType} will appear under this name in the Template Library. This will be displayed to organisations and their participants.`
                }
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
        <Box mt={0} mb={0}>
          <Controller
            control={control}
            name="description"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mb={2}>
                <FdTextField
                  id="description"
                  label="Description"
                  fullWidth
                  multiline
                  rows={3}
                  error={error}
                  helperText={
                    error
                      ? error.message
                      : 'For internal use only. This will not be displayed to participants.'
                  }
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
        </Box>
        {templateType === 'competition' && (
          <Box mt={0} mb={0}>
            <Controller
              control={control}
              name="story"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mb={2}>
                  <FdTextField
                    id="story"
                    label="Story"
                    fullWidth
                    multiline
                    rows={3}
                    error={error}
                    helperText={
                      error
                        ? error.message
                        : 'This will be displayed to organisations and their participants as a description of the theme of the competition. '
                    }
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

Details.propTypes = {
  editMode: PropTypes.bool,
  templateType: PropTypes.string,
};

Details.defaultProps = {
  editMode: false,
  templateType: '',
};

export default Details;
