import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FdProgress, useQueryRecursive } from '@fifthdomain/fe-shared';
import {
  initialValues,
  validationSchema,
} from '../../validation-schemas/Organisation';
import { listTasks } from '../../graphql/queries';
import { allocateTasksToOrg } from '../../graphql/mutations';
import { OrgContent } from './index';
import { successToastMessage } from '../../shared/utils/toast';
import { listTaskOrgs } from '../../queries/customQueries';
import { invalidateOrgTasksQuery } from '../../queries/invalidateQueries';

const EditOrgContent = () => {
  const [editTasks, setEditTasks] = useState(false);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset } = reactHookFormMethods;
  const { orgId } = useParams();

  const { data: orgData, loading: getOrgLoading } = useQueryRecursive(
    gql(listTaskOrgs),
    {
      variables: {
        filter: { orgId: { eq: orgId } },
      },
      skip: !orgId,
      onCompleted: (_data) => {
        reset({
          taskIds: _data.listTaskOrgs?.items.map((task) => task.taskId),
        });
      },
    },
  );

  const { data: listTasksData, loading: listTasksLoading } = useQueryRecursive(
    gql(listTasks),
    {
      variables: {
        filter: {
          status: { ne: 'DRAFT' },
        },
      },
    },
  );

  const [allocateTasksToOrgMutation] = useMutation(gql(allocateTasksToOrg), {
    refetchQueries: ['GetOrg'],
  });

  const orgTasks = orgData?.listTaskOrgs?.items
    ?.filter((t) => t?.type !== 'CONTAINER')
    ?.map(({ task }) => ({
      ...task,
      specialtyName: task?.specialty?.name,
      skills: task?.skills?.items.map((s) => s.skill?.name),
      techniqueTags: task?.skills?.items
        .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
        .flat(),
      technologyTags: task?.technologyTags?.items.map(
        (tt) => tt.technologyTag?.name,
      ),
      difficultyInteger: task?.difficulty,
      assigned: [
        ...new Set(
          task.orgs?.items?.map((orgAssigned) => orgAssigned?.org?.name).flat(),
        ),
      ],
      type: task?.modulePart !== null ? 'Labs' : 'Static',
    }));

  const allTasks = listTasksData?.listTasks?.items
    ?.filter(
      (item) =>
        !orgTasks?.map((ite) => ite?.id)?.includes(item?.id) &&
        item?.type !== 'CONTAINER',
    )
    ?.map((task) => ({
      ...task,
      specialtyName: task?.specialty?.name,
      skills: task?.specialty?.skills?.items.map((s) => s.name),
      techniqueTags: task?.skills?.items
        .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
        .flat(),
      technologyTags: task?.technologyTags?.items.map(
        (tt) => tt.technologyTag?.name,
      ),
      difficultyInteger: task?.difficulty,
      assigned: [
        ...new Set(
          task.orgs?.items?.map((orgAssigned) => orgAssigned?.org?.name).flat(),
        ),
      ],
      type: task?.modulePart !== null ? 'Labs' : 'Static',
    }));

  if (getOrgLoading || listTasksLoading) {
    return <FdProgress />;
  }

  return (
    <Box>
      <FormProvider {...reactHookFormMethods}>
        <form>
          <OrgContent
            data={editTasks ? allTasks : orgTasks}
            editTasks={editTasks}
            setEditTasksCallBack={(newTasks) => {
              setEditTasks(newTasks);
              if (newTasks?.length > 0) {
                allocateTasksToOrgMutation({
                  variables: {
                    orgId,
                    taskIds: newTasks,
                  },
                  onCompleted: () => {
                    invalidateOrgTasksQuery();
                    successToastMessage('Success! Content added to Org');
                  },
                });
                setEditTasks(false);
              }
            }}
            showEditView
          />
        </form>
      </FormProvider>
    </Box>
  );
};

export default EditOrgContent;
