import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdCard, FdCheckbox, FdTextField } from '@fifthdomain/fe-shared';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const OrgProducts = ({ allProducts, editMode }) => {
  const { control, getValues, setValue } = useFormContext();

  const products = useWatch({
    control,
    name: 'products',
  });

  const assessorSelected = products?.some((p) => p?.name === 'Assessor');

  const onProductChange = (productType, selected) => {
    const product = allProducts?.find((item) => item?.name === productType);
    // exclude selected product
    const otherProducts = products?.filter((item) => item?.id !== product?.id);
    // if its an existing product from db then use that
    const getProduct = () =>
      getValues('existingProducts').find((ep) => ep.id === product?.id) ||
      products.find((p) => p.id === product?.id);
    if (selected) {
      // existing product then use that, else add as new
      const newProduct = getProduct() || {
        ...product,
        pId: null,
      };
      setValue('products', [...otherProducts, newProduct]);
      // remove old entries
      setValue('removedProducts', [
        ...getValues('removedProducts').filter((rp) => rp !== product?.id),
      ]);
    } else {
      const flagForDeletion = products.find(
        (p) => p.id === product?.id && p.pId,
      );
      // flag for deletion
      if (flagForDeletion) {
        setValue('removedProducts', [
          ...getValues('removedProducts'),
          product?.id,
        ]);
      }
      setValue('products', otherProducts);
      // clear assessorLimit if Assessor de-selected
      if (product?.name === 'Assessor') {
        setValue('assessorLimit', null);
      }
    }
  };

  return (
    <FdCard
      heading={
        <Box display="flex" justifyContent="space-between">
          Add products
          {editMode}
        </Box>
      }
      subHeading="Add products that will be used by this organisation"
    >
      <Box display="flex" flexDirection="column">
        <Controller
          control={control}
          name="products"
          render={({ field: { value } }) => (
            <>
              <FdCheckbox
                name="Competitions"
                label="Competitions"
                checked={value
                  ?.map((item) => item?.name || item?.product?.name)
                  ?.includes('Competitions')}
                onChange={(selected) =>
                  onProductChange('Competitions', selected)
                }
              />
              <Box my={3}>
                <FdCheckbox
                  name="Assessor"
                  label="Assessor"
                  checked={value
                    ?.map((item) => item?.name || item?.product?.name)
                    ?.includes('Assessor')}
                  onChange={(selected) => onProductChange('Assessor', selected)}
                />
                {assessorSelected && (
                  <Controller
                    control={control}
                    name="assessorLimit"
                    render={({
                      field: { ref: _ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <Box mt={1} mb={2} mr={2}>
                        <FdTextField
                          id="assessorLimit"
                          label="Specify maximum limit for Assessor"
                          type="number"
                          width="324px"
                          inputRef={_ref}
                          data-cy="assessorLimit"
                          error={error}
                          helperText={error && error.message}
                          {...rest}
                        />
                      </Box>
                    )}
                  />
                )}
              </Box>
              <FdCheckbox
                name="Courses"
                label="Courses"
                checked={value
                  ?.map((item) => item?.name || item?.product?.name)
                  ?.includes('Labs')}
                onChange={(selected) => onProductChange('Labs', selected)}
              />
            </>
          )}
        />
      </Box>
    </FdCard>
  );
};

OrgProducts.propTypes = {
  editMode: PropTypes.element,
  allProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

OrgProducts.defaultProps = {
  allProducts: [],
  editMode: <></>,
};

export default OrgProducts;
