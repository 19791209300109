import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  FdCard,
  FdTable,
  FdSelect,
  FdTypography,
  FdButton,
  FdChip,
  FdModal,
  FdLoadingSpinner,
} from '@fifthdomain/fe-shared';
import { Controller, useFormContext } from 'react-hook-form';
import { checkTasksPublicMode } from '../../shared/utils/taskUtils';
import ViewTaskDrawer from '../Task/ViewTaskDrawer';
import ChallengeInfoCell from '../Task/ChallengeInfoCell';
import { updateTask } from '../../graphql/mutations';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import { getDifficultyLevel } from '../../shared/utils/difficultyMapping';
import TableHeaderColumnWithTooltip from '../ContentCatalogue/TableHeaderColumnWithTooltip';
import { getCommaSeparatedPlusSuffix } from '../../shared/utils/stringUtils';
import { PROFICIENCY_LEVELS } from '../../constants';

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const OrgContent = ({
  data,
  editTasks,
  setEditTasksCallBack,
  showEditView,
  title,
  orgDetails,
  selectedOrg,
  contentCatalogue,
  refetchTasks,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [publicModeModal, setPublicModeModal] = useState(false);
  const [publicMode, setPublicMode] = useState(false);
  const [activeTask, setActiveTask] = useState();
  const classes = useStyles();

  const [updateTaskMutation, { loading: updateTaskLoading }] = useMutation(
    gql(updateTask),
  );

  const { control, getValues } = useFormContext();

  const taskIds = getValues('taskIds');
  const tasks = data?.map((task) => {
    return {
      ...task,
      points: task?.recommendedPoints,
      difficulty:
        task?.difficulty > 5
          ? getDifficultyLevel(task?.difficulty)
          : PROFICIENCY_LEVELS[task?.difficulty],
      creator: task?.org?.name,
    };
  });

  const editTask = showEditView ? editTasks : true;

  const columns = [
    {
      field: 'challengeInfo',
      width: 420,
      headerName: 'Challenge Name',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <ChallengeInfoCell values={params.row} />,
    },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge can be classified."
        />
      ),
    },
    {
      field: 'skills',
      flex: 1,
      headerName: 'Skills',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Skills"
          tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
        />
      ),
      renderCell: (params) => getCommaSeparatedPlusSuffix(params.value),
    },
    {
      field: 'public',
      flex: 1,
      headerName: 'Public Catalogue',
      valueGetter: (params) => params?.value,
      renderCell: (params) => (
        <FdChip
          color={params.row.public === 'Yes' ? 'success' : 'default'}
          size="small"
          label={params?.row?.public}
        />
      ),
    },
    { field: 'points', flex: 1, headerName: 'Points' },
    {
      field: 'attempts',
      flex: 1,
      headerName: 'Attempts',
      valueGetter: (params) =>
        params.row.attempts > 0 ? '' : 'Never Solved before',
    },
    {
      field: 'difficulty',
      headerName: 'Proficiency',
    },
    { field: 'creator', flex: 1, headerName: 'Creator' },
    {
      field: 'assigned',
      flex: 1,
      headerName: 'Assigned to',
      unSort: true,
    },
    {
      field: 'type',
      flex: 1,
      headerName: 'Type',
    },
  ];

  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = data?.find((t) => t?.id === id);
        setActiveTask(activeTaskData);
      },
    },
  ];

  const selectedTasks = tasks?.filter((taskData) =>
    taskIds?.includes(taskData.id),
  );

  const updateTasksPublicMode = (updatedPublicMode) => {
    const taskPublicMode = publicMode ? 'Yes' : 'No';
    const updatingTasks = selectedTasks
      ?.filter((task) => task.public !== taskPublicMode)
      .map((t) => t.id);

    return Promise.all(
      updatingTasks?.map((taskId) =>
        updateTaskMutation({
          variables: {
            input: {
              id: taskId,
              public: updatedPublicMode,
            },
          },
        }),
      ),
    ).then(() => {
      setPublicModeModal(false);
      refetchTasks();
      successToastMessage(
        `Challenges made ${
          publicMode ? 'public in ' : 'non-public and removed from'
        } the Content Lease Catalogue!`,
      );
    });
  };

  return (
    <FdCard>
      <Controller
        control={control}
        name="taskIds"
        render={({ field: { value, onChange } }) => (
          <Box>
            {showEditView && (
              <Box display="flex" justifyContent="space-between">
                <FdTypography variant="h2">Assign Content</FdTypography>
                {editTask ? (
                  <Box display="flex" justifyContent="space-between">
                    <Box pr={1}>
                      <FdButton
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          setEditTasksCallBack(false);
                        }}
                      >
                        CANCEL
                      </FdButton>
                    </Box>
                    <Box>
                      <FdButton
                        variant="primary"
                        size="small"
                        onClick={() => {
                          setEditTasksCallBack(value);
                        }}
                      >
                        SAVE
                      </FdButton>
                    </Box>
                  </Box>
                ) : (
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={() => {
                      setEditTasksCallBack(true);
                    }}
                  >
                    Add Tasks/Challenges
                  </FdButton>
                )}
              </Box>
            )}

            <Box
              mt={2}
              mb={2}
              height="750px"
              width="100%"
              style={{ backgroundColor: 'white' }}
              data-cy="tasks-table"
            >
              <FdTable
                columnVisibilityModel={{
                  difficulty: false,
                  attempts: false,
                  points: false,
                }}
                toolbarSettings={{
                  title,
                  headerCustomElements: contentCatalogue
                    ? [
                        {
                          CustomElement: () => (
                            <Box className={classes.actionContainer}>
                              <FdButton
                                onClick={() => {
                                  if (
                                    checkTasksPublicMode(selectedTasks, 'Yes')
                                  )
                                    return;

                                  setPublicMode(true);
                                  setPublicModeModal(true);
                                }}
                              >
                                Make Public
                              </FdButton>
                              <FdButton
                                onClick={() => {
                                  if (checkTasksPublicMode(selectedTasks, 'No'))
                                    return;
                                  setPublicMode(false);
                                  setPublicModeModal(true);
                                }}
                              >
                                Make Non-Public
                              </FdButton>
                              <FdSelect
                                label=""
                                options={
                                  value?.length > 0
                                    ? [
                                        ...new Set(
                                          orgDetails?.map((item) => item?.name),
                                        ),
                                      ]
                                    : []
                                }
                                width="160px"
                                placeholder="SELECT ORG"
                                customPlaceHolder
                                onChange={(_value) => {
                                  selectedOrg(
                                    orgDetails?.find(
                                      (item) => item?.name === _value,
                                    ),
                                  );
                                }}
                              />
                            </Box>
                          ),
                        },
                      ]
                    : [],
                  filterOptions: [],
                  searchBox: true,
                  filterButton: editTask,
                }}
                selectionModel={editTask ? value : []}
                onSelectionModelChange={onChange}
                selection={editTask}
                disableSelectionOnClick
                actions={viewTaskActions}
                rows={tasks || []}
                columns={columns}
                pagination
                visibleSelection
                rowsPerPageOptions={[5, 10, 20]}
                tablePageSize={10}
                gridId="org-content-contents"
              />
            </Box>
          </Box>
        )}
      />

      {activeTask && openDrawer && (
        <ViewTaskDrawer
          activeTaskData={activeTask}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav', 'organisation']}
        />
      )}

      <FdModal
        size="md"
        title={
          <Box display="flex" alignItems="center">
            <FdTypography variant="h3">
              {`Make Challenges ${publicMode ? 'Public' : 'Non-Public'}?`}
            </FdTypography>
          </Box>
        }
        description={
          <FdTypography variant="body1" color="secondary">
            {publicMode
              ? ' Are you sure you want to make selected Challenges public and included in the FifthDomain Content Lease Catalogue? Organisations will be able to view these Challenges/Tasks in the FifthDomain Content Lease Catalogue and purchase leasing rights to them.'
              : 'Are you sure you want to make selected Challenges non-public and remove them from the FifthDomain Content Lease Catalogue? Organisations will no longer be able to lease this content. Organisations who have already purchased leasing rights to any of the selected Challenges will not be affected.'}
          </FdTypography>
        }
        confirm={updateTaskLoading ? <FdLoadingSpinner /> : 'CONFIRM'}
        dismiss="CANCEL"
        open={getValues('taskIds').length > 0 && publicModeModal}
        onConfirm={() => {
          updateTasksPublicMode(publicMode ? 'TRUE' : 'FALSE');
        }}
        onDismiss={() => {
          setPublicModeModal(false);
          warningToastMessage(
            `Challenge(s) not made ${
              publicMode ? 'public' : 'non-public'
            } in the Content Lease Catalogue`,
          );
        }}
      />
    </FdCard>
  );
};

OrgContent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  editTasks: PropTypes.bool,
  setEditTasksCallBack: PropTypes.func,
  showEditView: PropTypes.bool,
  title: PropTypes.string,
  contentCatalogue: PropTypes.bool,
  selectedOrg: PropTypes.func,
  orgDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  refetchTasks: PropTypes.func,
};

OrgContent.defaultProps = {
  data: [],
  editTasks: false,
  setEditTasksCallBack: () => {},
  showEditView: false,
  title: '',
  contentCatalogue: false,
  selectedOrg: () => {},
  refetchTasks: () => {},
  orgDetails: [],
};

export default OrgContent;
