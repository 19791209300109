export const getTemplateStatusColor = (status) => {
  switch (status) {
    case 'RELEASED':
      return 'success';
    case 'UNRELEASED':
      return 'default';
    default:
      return 'default';
  }
};
