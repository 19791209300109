import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdTypography, FdSelect, FdCard } from '@fifthdomain/fe-shared';
import { TASK_TYPE_COLOR } from '../../shared/utils/taskUtils';
import { DonutStacked, DonutGraphLegend, LevelTooltip } from '../Charts';
import {
  getDifficultyColor,
  getDifficultyLevel,
} from '../../shared/utils/difficultyMapping';
import { SPECIALTY_COLOR } from '../../constants';

const FdCatalogue = ({ orgDetails, allTasks, leaseCatalogue }) => {
  const [catalogue, setCatalogue] = useState();
  const [donutToolTipData, setDonutToolTipData] = useState(undefined);
  const [showDonutGraphCategoryTooltip, setShowDonutGraphCategoryTooltip] =
    useState(false);
  const [showDonutGraphDifficultyTooltip, setShowDonutGraphDifficultyTooltip] =
    useState(false);
  const [showDonutGraphTaskTypeTooltip, setShowDonutGraphTaskTypeTooltip] =
    useState(false);

  const fdCatalogue = catalogue
    ? allTasks?.filter((task) => task?.assigned?.includes(catalogue))
    : allTasks;

  // Proficiency Composition
  const donutGraphProficiencyData = _.chain(
    allTasks
      ?.map((at) => ({
        id: at.difficulty,
        label: getDifficultyLevel(at.difficulty),
      }))
      .filter((dv) => dv?.id),
  )
    .groupBy((i) => i?.label)
    .map((items, label) => ({
      id: label,
      label,
      value: items.length,
      color: getDifficultyColor(items[0].id),
    }))
    .value();
  const donutGraphProficiencyDataColors = donutGraphProficiencyData?.map(
    (d) => d.color,
  );

  // Specialty Composition
  const donutGraphSpecialtyData = _.chain(
    allTasks
      .map((t) => ({
        id: t?.specialtyId,
        name: t?.specialty?.name,
      }))
      .filter((tc) => tc?.id),
  )
    .groupBy((i) => i?.id)
    .map((items, id) => ({
      id,
      label: items[0].name,
      value: items.length,
      color: SPECIALTY_COLOR[items[0]?.name] || '',
    }))
    .value();
  const donutGraphSpecialtyDataColors = donutGraphSpecialtyData?.map(
    (d) => d.color,
  );
  // Task type Composition
  const getTasksByType = (_tasks, _type) =>
    _tasks?.filter((t) => _type === t.type);

  const donutGraphTypeData = (_allTasks) => {
    const taskType = [...new Set(_allTasks.map((item) => item?.type))];
    const taskTypeValue = [];
    for (let i = 0; i < taskType?.length; i += 1) {
      taskTypeValue.push({
        id: taskType[i],
        label: taskType[i],
        value: getTasksByType(_allTasks, taskType[i])?.length,
        color: TASK_TYPE_COLOR[taskType[i]],
      });
    }
    return taskTypeValue;
  };
  const donutGraphTypeDataColors = donutGraphTypeData(fdCatalogue).map((d) => {
    return d.color;
  });

  const HoverInfo = ({ value }) => {
    return (
      <Box
        width="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        ml={2}
      >
        <FdTypography variant="subtitle1" color="secondary">
          {` Hover over the ${value} segment to see more details.`}
        </FdTypography>
      </Box>
    );
  };
  HoverInfo.propTypes = {
    value: PropTypes.string.isRequired,
  };

  return (
    <FdCard style={{ width: leaseCatalogue ? '100%' : '20%' }}>
      <Box display="flex" flexDirection="column">
        {!leaseCatalogue && (
          <FdSelect
            label=""
            options={[...new Set(orgDetails?.map((item) => item?.name))]}
            width="247px"
            placeholder="FD Catalogue"
            onChange={(_value) => {
              setCatalogue(_value);
            }}
          />
        )}
        <Box display="flex" flexDirection="column" mb={2}>
          <Box mt={2}>
            <FdTypography variant="subtitle1">
              Professional Specialty composition
            </FdTypography>
          </Box>
          {donutGraphSpecialtyData.length > 0 ? (
            <Box display="flex" flexDirection="row" mt={2} mb={2}>
              <Box height="200px" width="200px" mr={2}>
                <DonutStacked
                  data={donutGraphSpecialtyData}
                  colors={donutGraphSpecialtyDataColors}
                  onHover={(_data) => {
                    setDonutToolTipData(_data?.data);
                    setShowDonutGraphCategoryTooltip(true);
                  }}
                  onLeave={() => {
                    setDonutToolTipData(undefined);
                    setShowDonutGraphCategoryTooltip(false);
                  }}
                />
              </Box>
              {showDonutGraphCategoryTooltip ? (
                <LevelTooltip
                  data={donutToolTipData}
                  totalTasks={fdCatalogue.length}
                />
              ) : (
                <HoverInfo value="specialty" />
              )}
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <FdTypography variant="caption">No data to present</FdTypography>
            </Box>
          )}
          <DonutGraphLegend data={donutGraphSpecialtyData} />
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" mb={2}>
          <Box mt={2}>
            <FdTypography variant="subtitle1">
              Proficiency composition
            </FdTypography>
          </Box>
          <Box display="flex" flexDirection="row" mt={2} mb={2}>
            <Box height="200px" width="200px" mr={2}>
              <DonutStacked
                data={donutGraphProficiencyData}
                colors={donutGraphProficiencyDataColors}
                onHover={(_data) => {
                  setDonutToolTipData(_data?.data);
                  setShowDonutGraphDifficultyTooltip(true);
                }}
                onLeave={() => {
                  setDonutToolTipData(undefined);
                  setShowDonutGraphDifficultyTooltip(false);
                }}
              />
            </Box>
            {showDonutGraphDifficultyTooltip ? (
              <LevelTooltip
                data={donutToolTipData}
                totalTasks={fdCatalogue.length}
              />
            ) : (
              <HoverInfo value="proficiency" />
            )}
          </Box>
          <DonutGraphLegend data={donutGraphProficiencyData} />
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" mb={2}>
          <Box mt={2}>
            <FdTypography variant="subtitle1">
              Challenge Type composition
            </FdTypography>
          </Box>
          <Box display="flex" flexDirection="row" mt={2} mb={2}>
            <Box height="200px" width="200px" mr={2}>
              <DonutStacked
                data={donutGraphTypeData(fdCatalogue)}
                colors={donutGraphTypeDataColors}
                onHover={(_data) => {
                  setDonutToolTipData(_data?.data);
                  setShowDonutGraphTaskTypeTooltip(true);
                }}
                onLeave={() => {
                  setDonutToolTipData(undefined);
                  setShowDonutGraphTaskTypeTooltip(false);
                }}
              />
            </Box>
            {showDonutGraphTaskTypeTooltip ? (
              <LevelTooltip
                data={donutToolTipData}
                totalTasks={fdCatalogue.length}
              />
            ) : (
              <HoverInfo value="type" />
            )}
          </Box>
          <DonutGraphLegend data={donutGraphTypeData(fdCatalogue)} />
        </Box>
      </Box>
    </FdCard>
  );
};

FdCatalogue.propTypes = {
  allTasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  orgDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  leaseCatalogue: PropTypes.bool,
};
FdCatalogue.defaultProps = {
  allTasks: [],
  orgDetails: [],
  leaseCatalogue: false,
};

export default FdCatalogue;
