export const preAssessmentMessage = `
# Assessment rules:

1.Do not attack or compromise the assessment infrastructure.

2.Do not share challenge solutions with other participants.

3.Do not engage in any activity which may impact or prevent others from solving challenges in the assessment.

# Accessing technical support:

1.To access technical support, click the support box icon located at the bottom right of the screen.

2.You can search for and read support articles within the support box.

3.You can create a support ticket by sending a message to our team within the support box.

Please note: Technical support is available from 09:00 to 17:00 AEST (23:00-07:00 GMT) on Australian business days.

# Tips for a successful assessment:

1.Practice some online Capture-the-Flag exercises before your assessment to familiarise yourself with the format of such exercises. 

2.Prepare any tools or software that you plan to use for your assessment prior to starting the assessment.

3.Once you have entered your assessment, take some time to review the available challenges and plan your approach for each.`;

export const preCompetitionMessage = `
# Competition rules:

1.Do not attack or compromise the competition infrastructure.

2.Do not share challenge solutions with other participants.

3.Do not engage in any activity which may impact or prevent others from solving challenges in the competition.

# Accessing technical support:

1.To access technical support, click the support box icon located at the bottom right of the screen.

2.You can search for and read support articles within the support box.

3.You can create a support ticket by sending a message to our team within the support box.

Please note: Technical support is available from 09:00 to 17:00 AEST (23:00-07:00 GMT) on Australian business days.

# Tips for a successful competition:

1.Practice some online Capture-the-Flag exercises before your competition to familiarise yourself with the format of such exercises. 

2.Prepare any tools or software that you plan to use for your competition prior to starting the competition.

3.Once you have entered your competition, take some time to review the available challenges and plan your approach for each.`;

export const postAssessmentMessage =
  'Your assessment has now finished. You can contact your assessment organiser for information about what might occur next.';

export const postCompetitionMessage =
  'The competition has ended. You can visit the scoreboard to see how other participants went.';
