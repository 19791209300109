export const createUpdateOrg = ({
  orgValues,
  createOrgMutation,
  updateOrgMutation,
}) => {
  const { orgId, orgName, pricingTier } = orgValues;
  const actionMutation = orgId ? updateOrgMutation : createOrgMutation;
  // create or update org
  actionMutation({
    variables: {
      input: {
        id: orgId || undefined,
        name: orgName,
        pricingTier: pricingTier.toUpperCase(),
      },
    },
  });
};

export const createUpdateProducts = async ({
  products,
  createProductOrgMutation,
  deleteProductOrgMutation,
  orgId,
  removedProducts = [],
  updateOrgMutation,
  assessorLimit,
  remainingInvitees,
}) => {
  const productsToBeAdded = [
    ...new Set(products?.filter((p) => !p.pId).map((p) => p.id)),
  ];
  const productsToBeRemoved = removedProducts.filter(
    (rp) => !productsToBeAdded.includes(rp),
  );
  const productsContainAssessor = products?.some((p) => p.name === 'Assessor');
  const productsRemoved = [];
  const productsAdded = [];
  // remove products
  await Promise.all(
    productsToBeRemoved?.map(async (productId) => {
      await deleteProductOrgMutation({
        variables: {
          input: {
            orgId,
            productId,
          },
        },
        onCompleted: (_data) =>
          productsRemoved.push(_data.deleteProductOrg?.product?.id),
      });
    }),
  );
  // add products
  await Promise.all(
    productsToBeAdded?.map(async (productId) => {
      await createProductOrgMutation({
        variables: {
          input: {
            orgId,
            productId,
          },
        },
        onCompleted: (_data) => {
          productsAdded.push(_data.createProductOrg?.product?.id);
        },
      });
    }),
  );
  /* if Assessor is a product, then update assessorLimit & remainingInvitees with given value
     else update it as null */
  updateOrgMutation({
    variables: {
      input: {
        id: orgId,
        maxInvitees: productsContainAssessor ? assessorLimit : null,
        remainingInvitees: productsContainAssessor ? remainingInvitees : null,
      },
    },
  });
  return { productsRemoved, productsAdded };
};

// as product name Labs is called as Courses in front-end
export const getProductName = (name) => (name === 'Labs' ? 'Courses' : name);

export const createUpdateThemes = async ({
  createThemeOrgMutation,
  deleteThemeOrgMutation,
  updateOrgMutation,
  orgId,
  orgSelectedTheme,
  removedThemes = [],
  addedThemes = [],
}) => {
  const themesRemoved = [];
  const themesAdded = [];

  if (removedThemes && removedThemes.length > 0) {
    await Promise.all(
      removedThemes?.map(async (themeOrg) => {
        await deleteThemeOrgMutation({
          variables: {
            input: {
              id: themeOrg.id,
            },
          },
          onCompleted: (_data) =>
            themesRemoved.push(_data.deleteThemeOrg?.themeOrg?.Id),
        });
      }),
    );
  }

  if (addedThemes && addedThemes.length > 0) {
    await Promise.all(
      addedThemes?.map(async (theme) => {
        await createThemeOrgMutation({
          variables: {
            input: {
              orgId,
              themeId: theme.id,
            },
          },
          onCompleted: (_data) => {
            themesAdded.push(_data.createThemeOrg?.themeOrg?.id);
          },
        });
      }),
    );
  }
  
  if (orgSelectedTheme && orgSelectedTheme?.id) {

    updateOrgMutation({
      variables: {
        input: {
          id: orgId,
          themeId: orgSelectedTheme.id,
        },
      },
    });
  }

  return { themesRemoved, themesAdded };
};
