import Yup from '../Common';

export const addInitialValues = {
  addCustomerSkills: [],
};

export const addValidationSchema = Yup.object().shape({
  addCustomerSkills: Yup.array()
    .of(
      Yup.object().shape({
        customerSkillName: Yup.string()
          .required('Please enter a Customer Skill Name.')
          .test(
            'noTrailing&LeadingWhitespace',
            'Please enter a Customer Skill Name.',
            (value) => value && value.length === value.trim().length,
          )
          .max(150, 'Customer Skill Name cannot be over 150 characters.'),
        fdSkill: Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.string(),
            }),
          )
          .test({
            message: 'Please choose a FifthDomain skill.',
            test: (value) => value && value?.length !== 0,
          }),
      }),
    )
    .unique(
      'customerSkillName',
      'Customer Skill Name already exists in this organisation. Please enter a unique name.',
    )
    .min(1),
});

export const editInitialValues = {
  customerSkillName: '',
  fdSkill: '',
};

export const editValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a Customer Skill Name.')
    .test(
      'noTrailing&LeadingWhitespace',
      'Please enter a Customer Skill Name.',
      (value) => value && value.length === value.trim().length,
    )
    .max(150, 'Customer Skill Name cannot be over 150 characters.'),
  fdSkill: Yup.array().nullable().min(1, 'Please choose a FifthDomain skill.'),
});
