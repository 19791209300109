import Yup from '../Common';

export const initialValues = {
  name: '',
  workRoleSkills: [],
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a Work Role Name.')
    .test(
      'noTrailing&LeadingWhitespace',
      'Please enter a Work Role Name.',
      (value) => value && value.length === value.trim().length,
    )
    .max(100, 'Work Role Name cannot be over 100 characters.'),
  workRoleSkills: Yup.array()
    .of(
      Yup.object().shape({
        workRoleSkillId: Yup.string(),
        workRoleSkillName: Yup.string(),
        difficulty: Yup.string().required(
          'Please select a Proficiency for each skill. ',
        ),
      }),
    )
    .test({
      message: 'Please choose at least one customer skill.',
      test: (value) => value && value?.length !== 0,
    }),
});
