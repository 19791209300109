export const getAssessmentChallengesTechniques = (tasks) => {
  const uniqueTechniques = tasks
    .flatMap((task) => task.techniqueTags || [])
    .reduce((acc, technique) => {
      if (technique && !acc.includes(technique)) acc.push(technique);
      return acc;
    }, []);

  return tasks.reduce(
    (acc, task) => {
      const taskName = task.name;
      const { recommendedPoints } = task;
      const technologiesLength = task?.technologyTags?.length || 1;

      const taskTechniques = task?.techniqueTags;

      taskTechniques.forEach((technique) => {
        const techniquesLength = taskTechniques.length || 1;
        const value = Math.floor(
          recommendedPoints / techniquesLength / technologiesLength,
        );

        const techniqueData = acc.find((item) => item.technique === technique);
        if (techniqueData) {
          techniqueData[taskName] = value;
        } else {
          acc.push({ technique, [taskName]: value });
        }
      });

      return acc;
    },
    uniqueTechniques.map((technique) => ({ technique })),
  );
};

export const getAssessmentChallengesTechnologies = (tasks) => {
  const uniqueTechnologies = tasks
    .flatMap((task) => task.technologyTags || [])
    .reduce((acc, technology) => {
      if (technology && !acc.includes(technology)) acc.push(technology);
      return acc;
    }, []);

  return uniqueTechnologies.map((technology) => {
    const challenges = tasks.reduce((acc, task) => {
      const taskName = task.name;
      const { recommendedPoints } = task;
      const technologies = task.technologyTags;
      const techniquesLength = task?.techniqueTags.length || 1;
      if (technologies.includes(technology)) {
        const value = Math.floor(
          recommendedPoints / techniquesLength / technologies.length,
        );
        acc[taskName] = value;
      }

      return acc;
    }, {});

    return { technology, ...challenges };
  });
};

export const getSkillsProficienciesMappedData = (
  tasksSkillsProficiencies,
  skillsData,
) => {
  const proficiencyLevels = [
    'Novice',
    'Advanced Beginner',
    'Competent',
    'Proficient',
    'Expert',
  ];

  const proficiencySkills =
    Object.keys(tasksSkillsProficiencies)?.map((p) => ({
      proficiency: p,
      skills: [
        ...new Set(tasksSkillsProficiencies[p].flatMap((item) => item.skills)),
      ],
    })) || [];

  const skillsProficienciesData = proficiencyLevels.reduce(
    (acc, _proficiency, index) => {
      const id = index + 1;

      const data = skillsData?.map((skill) => {
        const foundProficiencySkills = proficiencySkills?.some(
          (p) =>
            Number(p.proficiency) === id &&
            p?.skills?.map((_p) => _p)?.some((_s) => _s === skill?.name),
        );

        return {
          x: skill?.alias,
          proficiency: proficiencyLevels[index],
          skill: skill?.name,
          y: foundProficiencySkills ? id : undefined,
        };
      });

      acc.push({
        id,
        data,
      });

      return acc;
    },
    [],
  );

  return skillsProficienciesData;
};
