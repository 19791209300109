import { format as dateFormat, utcToZonedTime } from 'date-fns-tz';
import { minutesToHours } from 'date-fns';

const userTimeProfile = Intl.DateTimeFormat().resolvedOptions();
const { timeZone } = userTimeProfile;

const defaultDateFormatPattern = 'dd/MM/yyyy';
const timeZoneAbbreviated = () => {
  const { 1: tz } = new Date().toString().match(/\((.+)\)/);

  // In Chrome browser, new Date().toString() is
  // "Thu Aug 06 2020 16:21:38 GMT+0530 (India Standard Time)"

  // In Safari browser, new Date().toString() is
  // "Thu Aug 06 2020 16:24:03 GMT+0530 (IST)"

  if (tz.includes(' ')) {
    return tz
      .split(' ')
      .map(([first]) => first)
      .join('');
  }
  return tz;
};

export const sortByDateField =
  (fieldName, sortOrder = 'asc') =>
  (a, b) => {
    const dateA = new Date(a[fieldName]);
    const dateB = new Date(b[fieldName]);

    if (sortOrder.toLowerCase() === 'desc') {
      return dateB - dateA;
    }
    return dateA - dateB;
  };

export const sortedByDate = (arr) =>
  arr?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) || [];

export const getDateTimeZone = (dateTime) => {
  return utcToZonedTime(new Date(dateTime), timeZone);
};

export const getDateTimeZoneCustom = (dateTime, format) => {
  const dateFormatPattern = format || defaultDateFormatPattern;
  return dateFormat(getDateTimeZone(dateTime), dateFormatPattern);
};

export const sortedByCreatedDate = (arr) =>
  arr?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

export const getDateTimeZoneFormatted = (dateTime, hideTimeZoneAbbreviated) => {
  return (
    dateFormat(getDateTimeZone(dateTime), defaultDateFormatPattern) +
    (!hideTimeZoneAbbreviated ? ` ${timeZoneAbbreviated()}` : '')
  );
};

export const formatMinutes = (timeInMinutes, hideHoursIfZero) => {
  // less than 60 show as seconds
  if (timeInMinutes > 0 && timeInMinutes < 1)
    return `${Math.round(timeInMinutes * 60)}s`;
  // only minutes
  if (timeInMinutes > 0 && timeInMinutes < 60)
    return `${Math.round(timeInMinutes)}m`;
  // both hours & minutes
  const hours = minutesToHours(timeInMinutes);
  const remainingMinutes = Math.round(timeInMinutes % 60);

  return [
    hours > 0 ? `${hours}h` : hideHoursIfZero ? '' : '0h',
    remainingMinutes > 0 ? `${remainingMinutes}m` : '0m',
  ].join(' ');
};
