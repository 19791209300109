import Yup from '../Common';

export const initialValues = {
  orgId: '',
  orgName: '',
  pricingTier: '',
  orgSelectedTheme: '',
  removedThemes: [],
  addedThemes: [],
  users: [],
  products: [],
  taskIds: [],
  assessorLimit: null,
  removedProducts: [],
  existingProducts: [],
  logos: [],
  selectedTheme: '',
  key: '',
  file: {},
};

export const validationSchema = Yup.object().shape({
  orgId: Yup.string(),
  orgName: Yup.string()
    .required('Please enter an Org Name')
    .max(150, 'Org name must be 150 characters or less')
    .noWhitespace('Please enter a Org Name'),
  pricingTier: Yup.string().required(
    'Please select a pricing tier for this organisation.',
  ),
  users: Yup.array().of(Yup.string()),
  products: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().nullable(),
      name: Yup.string(),
      createdAt: Yup.date().nullable(),
      updatedAt: Yup.date().nullable(),
      pId: Yup.string().nullable(),
    }),
  ),
  removedProducts: Yup.array().of(Yup.string()),
  taskIds: Yup.array().of(Yup.string()).min(1),
  assessorLimit: Yup.number()
    .min(1, 'Please enter a positive, whole number')
    .transform((_, val) => (val ? Number(val) : null))
    .nullable(),
  existingProducts: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().nullable(),
      pId: Yup.string().nullable(),
    }),
  ),
  logos: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().nullable(),
      theme: Yup.string().nullable(),
      url: Yup.string().nullable(),
    }),
  ),
  selectedTheme: Yup.string().nullable(),
  key: Yup.string().nullable(),
  file: Yup.object(),
  orgSelectedTheme: Yup.string().nullable(),
});
