import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';

const HeatMapCustomLegend = ({ labelWidth, width, specialtyLegends }) => (
  <Box
    style={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    }}
  >
    <Box
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box width={labelWidth} />
      <Box width={width} display="flex">
        {specialtyLegends?.map((sl, idx) => (
          <Box
            width={sl.width}
            style={{
              textAlign: 'center',
              borderLeft: idx === 0 ? '1px solid grey' : 'none',
              borderRight: '1px solid grey',
            }}
          >
            <FdTypography variant="body2">{sl.specialty}</FdTypography>
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
);

HeatMapCustomLegend.propTypes = {
  labelWidth: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  specialtyLegends: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default HeatMapCustomLegend;
