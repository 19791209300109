import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import {
  FdCard,
  FdSelect,
  FdButton,
  FdRadioGroup,
  FdTypography,
} from '@fifthdomain/fe-shared';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { warningToastMessage } from '../../shared/utils/toast';

const OrgThemes = ({ allThemes, orgThemes, editMode }) => {
  const { control, getValues, setValue } = useFormContext();

  const [newAddedThemes, setNewAddedThemes] = useState([]);
  const [removedThemes, setRemovedThemes] = useState([]);

  const defaultThemes = allThemes.filter((theme) => theme.special === 'NO');
  const specialThemes = allThemes.filter((theme) => theme.special === 'YES');

  const orgThemesDB = specialThemes.filter((obj1) =>
    orgThemes.some((obj2) => obj1.id === obj2.themeId),
  );

  const allOrgThemesAll = defaultThemes
    .concat(orgThemesDB)
    .concat(newAddedThemes);
  const allOrgThemes = allOrgThemesAll.filter(
    (item1) => !removedThemes.some((item2) => item2.themeId === item1.id),
  );

  useEffect(() => {
    if (!editMode) {
      setValue('orgSelectedTheme', 'LIGHT');
    }
  }, [editMode, allThemes, setValue]);

  return (
    <FdCard
      heading={
        <Box display="flex" justifyContent="space-between">
          Assign Themes
          {editMode}
        </Box>
      }
      subHeading="Select special themes to apply to this organisation and set a default theme for users of the organisation."
    >
      <Box mt={3} mb={2}>
        <FdTypography variant="subtitle1">Assign Special Themes</FdTypography>
        <Box mt={1}>
          <FdTypography variant="body1" color="secondary">
            Assign special themes to this organisation. Note that every
            organisation will automatically have access to the Light Theme and
            Retro Theme.
          </FdTypography>
        </Box>
      </Box>

      <Controller
        control={control}
        name="specialThemes"
        render={({ field: { ...rest } }) => (
          <Box mb={2}>
            <FdSelect
              id="specialThemes"
              name="specialThemes"
              options={specialThemes.map((t) => t.name)}
              defaultSelected={orgThemesDB.map((t) => t.name)}
              {...rest}
              multiple
            />
          </Box>
        )}
      />

      <Box mb={3}>
        <FdButton
          onClick={() => {
            const selectedThemes = getValues('specialThemes');
            const orgDefaultTheme = getValues('orgSelectedTheme');

            if (orgDefaultTheme) {
              const inDefaultTheme = defaultThemes
                .map((d) => d.name)
                .includes(orgDefaultTheme);
              const inSepcialTheme = selectedThemes.includes(orgDefaultTheme);
              if (!inDefaultTheme && !inSepcialTheme) {
                warningToastMessage(
                  `You can not remove the special theme [${orgDefaultTheme}] because it was selected as the default theme for this org.`,
                );
                return;
              }
            }
            const addedThemesDB = allThemes.filter((d) =>
              selectedThemes
                .filter(
                  (item) => !orgThemesDB.map((t) => t.name).includes(item),
                )
                .includes(d.name),
            );
            const removedThemesDB = orgThemes.filter((ot) =>
              orgThemesDB
                .filter((item) => !selectedThemes.includes(item.name))
                .map((r) => r.id)
                .includes(ot.themeId),
            );
            setValue('removedThemes', removedThemesDB);
            setValue('addedThemes', addedThemesDB);
            setNewAddedThemes(addedThemesDB);
            setRemovedThemes(removedThemesDB);
          }}
          variant="primary"
        >
          Update
        </FdButton>
      </Box>

      <Box mb={2}>
        <FdTypography variant="subtitle1">Assign Default Theme</FdTypography>
        <Box mt={1}>
          <FdTypography variant="body1" color="secondary">
            Assign a default theme for every user for this organisation. The
            special themes selected above will reflect below.
          </FdTypography>
        </Box>
      </Box>

      <Controller
        control={control}
        name="orgSelectedTheme"
        render={({
          field: { value: fieldValue, ...rest },
          formState: { ref },
        }) => {
          return (
            <Box ml={1} mt={2} mb={2}>
              <FdRadioGroup
                id="orgSelectedTheme"
                options={allOrgThemes.map((t) => t.name)}
                onChange={(option) => {
                  const orgSelectedTheme = allOrgThemes.filter(
                    (ot) => ot.name === option.value,
                  );
                  setValue('orgSelectedTheme', orgSelectedTheme[0]?.name);
                }}
                defaultValue={fieldValue}
                inputRef={ref}
              />
            </Box>
          );
        }}
      />
    </FdCard>
  );
};

OrgThemes.propTypes = {
  editMode: PropTypes.element,
  allThemes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      special: PropTypes.string,
    }),
  ),
  orgThemes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      orgId: PropTypes.string,
      themeId: PropTypes.string,
    }),
  ),
};
OrgThemes.defaultProps = {
  editMode: null,
  allThemes: [],
  orgThemes: [],
};

export default OrgThemes;
