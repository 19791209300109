import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  FdTextField,
  FdModal,
  FdDelayed,
  FdAutocomplete,
} from '@fifthdomain/fe-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { editValidationSchema } from '../../../validation-schemas/WorkRole/customerSkills';

const EditCustomerSkill = ({
  openModal,
  onConfirm,
  onDismiss,
  fdSkills,
  loading,
}) => {
  const reactHookFormMethods = useForm({
    defaultValues: openModal,
    resolver: yupResolver(editValidationSchema),
    mode: 'all',
  });
  const { control, getValues, setValue, trigger, reset, clearErrors } =
    reactHookFormMethods;

  useEffect(() => {
    reset(openModal);
  }, [openModal, reset]);

  return (
    <FormProvider {...reactHookFormMethods}>
      <form style={{ width: '100%' }}>
        <FdModal
          size="lg"
          title="Edit Customer Skill"
          confirm={loading ? 'Loading' : 'Save'}
          disableConfirm={loading}
          dismiss="CANCEL"
          open={openModal}
          onConfirm={async () => {
            const result = await trigger();
            // submit confirm with values
            if (result) {
              onConfirm(getValues());
            }
          }}
          onDismiss={() => {
            reset();
            onDismiss();
          }}
          data-cy="open-modal"
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1}>
                  <FdTextField
                    id="name"
                    label="Customer Skill Name"
                    placeholder="Enter Customer Skill Name"
                    required
                    fullWidth
                    error={error}
                    helperText={error?.message}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Box my={3}>
              <Controller
                control={control}
                name="fdSkill"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <FdDelayed>
                      <Box>
                        <FdAutocomplete
                          id="fdSkill"
                          label="Mapped FifthDomain Skill(s)"
                          placeholder="Select FifthDomain Skill(s)"
                          options={fdSkills}
                          multiple
                          fullWidth
                          error={error}
                          helperText={error && error.message}
                          data-cy="fdSkill"
                          selectAllLabel="Select all"
                          optionLabel="name"
                          optionValue="id"
                          defaultSelected={rest?.value}
                          {...rest}
                          inputRef={ref}
                        />
                      </Box>
                    </FdDelayed>
                  );
                }}
              />
            </Box>
          </Box>
        </FdModal>
      </form>
    </FormProvider>
  );
};

EditCustomerSkill.propTypes = {
  loading: PropTypes.bool.isRequired,
  openModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  fdSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default EditCustomerSkill;
