import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { ResponsiveHeatMap } from '@nivo/heatmap';

const HeatmapNivo = ({
  data,
  yAxisLabel,
  color,
  customTopLegend,
  customBottomLegend,
  ...props
}) => (
  <Box height="60%">
    {customTopLegend && <>{customTopLegend}</>}
    <ResponsiveHeatMap
      data={data}
      margin={{ top: 10, right: 20, bottom: 40, left: 50 }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        legendOffset: 46,
      }}
      axisLeft={{
        tickSize: 1,
        tickPadding: 5,
        tickRotation: -1,
        legend: `${yAxisLabel}`,
        legendPosition: 'middle',
        legendOffset: -30,
      }}
      axisTop={null}
      axisRight={null}
      yInnerPadding={0.1}
      xInnerPadding={0.1}
      colors={() => color}
      emptyColor="#F1F0F0"
      forceSquare
      enableLabels={false}
      legends={[]}
      {...props}
    />
    {customBottomLegend && <>{customBottomLegend}</>}
  </Box>
);

HeatmapNivo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  yAxisLabel: PropTypes.string,
  color: PropTypes.string,
  customBottomLegend: PropTypes.node,
  customTopLegend: PropTypes.node,
};

HeatmapNivo.defaultProps = {
  yAxisLabel: '',
  color: '#607094',
  customTopLegend: null,
  customBottomLegend: null,
};

export default HeatmapNivo;
