import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import {
  FdTab,
  FdIconsV5,
  FdTypography,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import { FdCatalogue } from '../Organisation';

const CatalogueTabs = ({ tasks, currentTasksData }) => {
  const { InfoOutlined } = FdIconsV5;
  return (
    <Box>
      <FdTab
        label={[
          {
            customLabel: (
              <Box display="flex" alignItems="center">
                <FdTypography variant="subtitle2">Preview</FdTypography>
                <FdTooltip title="The Preview tab shows compositions of challenge profesional specialty, proficiency, and type in your organisation’s library if a transaction on currently selected challenges is made.">
                  <span size="small" style={{ marginLeft: '10px' }}>
                    <InfoOutlined />
                  </span>
                </FdTooltip>
              </Box>
            ),
            index: 0,
            data: () => <FdCatalogue allTasks={tasks} leaseCatalogue />,
          },
          {
            label: 'Current View',
            index: 1,
            data: () => (
              <FdCatalogue allTasks={currentTasksData} leaseCatalogue />
            ),
          },
        ]}
      />
    </Box>
  );
};

CatalogueTabs.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentTasksData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CatalogueTabs;
