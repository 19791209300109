import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, FormLabel, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';
import {
  FdButton,
  FdTypography,
  FdSelect,
  FdChip,
  FdCard,
} from '@fifthdomain/fe-shared';
import { gql, useMutation } from '@apollo/client';
import {
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import { manageOrgUrl, removeLogoFromOrg } from '../../graphql/mutations';
import { getPreSignedUrl, uploadFileToS3 } from '../../shared/utils/fileUtils';
import FileAttachment from './FileAttachment';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: '20rem',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const LogoUpload = ({ allThemes, orgThemes, editMode, setEditLogo }) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [logo, setLogo] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const params = useParams();

  const classes = useStyles();
  const { getValues, control, setValue, watch } = useFormContext();

  const [manageOrgUrlMutation, { loading: manageOrgUrlLoading }] = useMutation(
    gql(manageOrgUrl),
  );

  const [removeLogoMutation, { loading: removeLogoLoading }] = useMutation(
    gql(removeLogoFromOrg),
  );

  const maxSize = 2 * 1024 * 1024; // 2 MB
  const types = ['image/png', 'image/jpeg'];

  const handleChange = (e) => {
    const selected = e.target.files[0];

    if (selected && types.includes(selected.type) && selected.size <= maxSize) {
      setPreviewUrl(URL.createObjectURL(selected));
      setValue('file', selected);
      setValue('key', selected.name);
    } else {
      let errorMessage = '';
      if (selected && !types.includes(selected.type)) {
        errorMessage = 'Error! File type not supported';
      } else if (selected && selected.size > maxSize) {
        errorMessage = 'Error! Image size not compatible';
      }
      setValue('file', {});
      setValue('key', '');
      setPreviewUrl(null);
      errorToastMessage(errorMessage);
    }
  };

  const { logos, file } = getValues();
  const selectedTheme = watch('selectedTheme');
  const key = watch('key');
  const newOrgId = watch('orgId');
  const orgId = editMode ? params.orgId : newOrgId;

  const onDelete = () => {
    if (logo) {
      removeLogoMutation({
        variables: {
          theme: logo?.theme,
          orgId,
        },
        onCompleted: () => {
          successToastMessage('Success! Logo removed');
          setValue('selectedTheme', logo.theme);
          const remainingLogos = logos.filter(
            (remainingLogo) => remainingLogo.theme !== logo.theme,
          );
          setValue('logos', remainingLogos);
        },
      });
    }

    setValue('key', '');
    setValue('file', '');

    setPreviewUrl(null);
    setLogo(null);
  };

  const handleUpload = async () => {
    if (!key || !selectedTheme) {
      errorToastMessage('Error! No file or theme selected');
      return;
    }

    try {
      setUploadLoading(true);
      await uploadFileToS3({ key: `${orgId}/${key}`, file });

      manageOrgUrlMutation({
        variables: {
          orgId,
          action: 'ADD',
          key,
          theme: selectedTheme,
        },

        onCompleted: async () => {
          successToastMessage('Success! File was uploaded');
          setUploadLoading(false);

          const url = await getPreSignedUrl(`${orgId}/${key}`);
          setPreviewUrl(url);
          setValue('key', '');
          setValue('logos', [
            ...logos,
            {
              key: `${orgId}/${key}`,
              theme: selectedTheme,
              url,
            },
          ]);

          setLogo({
            key: `${orgId}/${key}`,
            theme: selectedTheme,
            url,
          });
        },
        onError: (_error) => {
          errorToastMessage(_error.message);
          setUploadLoading(false);
        },
      });
    } catch (error) {
      errorToastMessage('Error uploading file');
      setUploadLoading(false);
    }
  };

  const defaultThemes = allThemes.filter((theme) => theme.special === 'NO');
  const specialThemes = allThemes.filter((theme) => theme.special === 'YES');
  const orgThemesDB = specialThemes.filter((obj1) =>
    orgThemes.some((obj2) => obj1.id === obj2.themeId),
  );
  const allOrgThemes = defaultThemes.concat(orgThemesDB);

  return (
    <FdCard
      heading={
        editMode ? (
          <Box display="flex" justifyContent="space-between">
            <FdTypography variant="h3">Org Logos</FdTypography>
            {editMode && (
              <Box className={classes.btnContainer}>
                <FdButton
                  variant="secondary"
                  size="small"
                  onClick={() => {
                    warningToastMessage('Changes to org not saved');
                    setEditLogo();
                    setValue('key', '');
                    setValue('file', '');
                    setPreviewUrl(null);
                    setLogo(null);
                  }}
                >
                  cancel
                </FdButton>
                <FdButton
                  size="small"
                  variant="primary"
                  onClick={() => {
                    successToastMessage('Changes to org saved');
                    setEditLogo();
                  }}
                >
                  save
                </FdButton>
              </Box>
            )}
          </Box>
        ) : (
          'Upload Logos'
        )
      }
      optional={!editMode}
      subHeading={
        <Box>
          {!editMode && (
            <FdTypography variant="body1">
              Upload logos for the organisation
            </FdTypography>
          )}
          <Box display="flex" mt={2}>
            <FdTypography
              variant="body1"
              style={{ color: 'rgba(0, 0, 0, 0.87)' }}
              fontWeight="medium"
            >
              Org Logo
            </FdTypography>
            <FdTypography variant="body1" color="secondary">
              &nbsp;optional
            </FdTypography>
          </Box>
          <FdTypography variant="body1" color="secondary">
            <strong>Note: </strong>
            To white-label Assessment Insight Reports, a logo must be uploaded
            for the light theme.
          </FdTypography>
        </Box>
      }
    >
      <Box mt={2} className={classes.container}>
        <Box className={classes.logoContainer}>
          <Box mb={1}>
            <FdTypography color="secondary" variant="body1">
              <Box>
                <Box>File formats supported:</Box>
                <ul>
                  <li>&apos;.png&apos;(preferred)</li>
                  <li>&apos;.jpg&apos;</li>
                </ul>
                <Box>Logo image placeholder dimensions:</Box>
                <ul>
                  <li>Width: 430px</li>
                  <li>Height: 354px</li>
                </ul>
              </Box>
              <Box mt={1}>File Size Limit: 2MB</Box>
            </FdTypography>
          </Box>
          <Controller
            control={control}
            name="selectedTheme"
            render={({ field }) => (
              <Box mt={1} mb={2} mr={2}>
                <FdSelect
                  id="selectedTheme"
                  options={allOrgThemes.map((t) => t.name)}
                  placeholder="Select Theme"
                  {...field}
                  onChange={(val) => {
                    const selectedLogo = logos?.find(
                      (logoItem) => logoItem.theme === val,
                    );

                    setLogo(selectedLogo);
                    setPreviewUrl(selectedLogo?.url);
                    setValue('selectedTheme', val);
                  }}
                />
              </Box>
            )}
          />
          <Box className={classes.btnContainer}>
            <input
              hidden
              accept="image/*"
              id="browse-btn"
              type="file"
              onChange={handleChange}
              disabled={!isEmpty(logo)}
            />
            <FormLabel htmlFor="browse-btn">
              <FdButton
                variant="secondary"
                component="span"
                disabled={!isEmpty(logo)}
              >
                Browse
              </FdButton>
            </FormLabel>
            {logo && (
              <FileAttachment
                logoKey={logo?.key?.split('/')[1]}
                url={logo?.url}
                onClickAttachment={() => {}}
              />
            )}
            {key && (
              <FdChip
                onDelete={onDelete}
                color="default"
                size="medium"
                label={key}
              />
            )}
            {!logo && !key && (
              <FdTypography variant="body1" color="secondary">
                No file selected
              </FdTypography>
            )}
          </Box>
          <Box mt={2}>
            <FdButton
              onClick={logo ? onDelete : handleUpload}
              disabled={
                manageOrgUrlLoading || uploadLoading || removeLogoLoading
              }
            >
              {manageOrgUrlLoading || uploadLoading || removeLogoLoading
                ? 'Loading...'
                : logo
                ? 'remove'
                : 'Upload'}
            </FdButton>
          </Box>
        </Box>
        {previewUrl && (
          <Box className={classes.previewContainer}>
            <FdTypography variant="body1" fontWeight="medium">
              Preview Image
            </FdTypography>
            <img src={previewUrl} alt="preview" height="354" width="430" />
          </Box>
        )}
      </Box>
    </FdCard>
  );
};

LogoUpload.propTypes = {
  editMode: PropTypes.bool.isRequired,
  setEditLogo: PropTypes.func,
  allThemes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      special: PropTypes.string,
    }),
  ),
  orgThemes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      orgId: PropTypes.string,
      themeId: PropTypes.string,
    }),
  ),
};

LogoUpload.defaultProps = {
  setEditLogo: () => {},
  allThemes: [],
  orgThemes: [],
};

export default LogoUpload;
