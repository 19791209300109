import React from 'react';
import PropTypes from 'prop-types';
import { FdTable, FdProgress } from '@fifthdomain/fe-shared';

const ChallengeUsageTable = ({
  rows,
  onRowClick,
  taskName,
  getTaskLoading,
  usageDetail,
}) => {
  const usageColumns = [
    { field: 'name', flex: 1, headerName: 'Name' },
    { field: 'assigned', flex: 2, headerName: 'Assigned to', unSort: true },
    { field: 'noOfOrgs', flex: 1, headerName: 'No. of Orgs' },
    { field: 'noOfEvents', flex: 1, headerName: 'No. of Events' },
  ];
  const usageDetailcolumns = [
    { field: 'eventName', flex: 1, headerName: 'Event Name' },
    { field: 'orgName', flex: 1, headerName: 'Org Name' },
    { field: 'time', width: 150, headerName: 'MM/YYYY' },
    { field: 'noOfSolves', width: 150, headerName: 'No. of Solves' },
  ];

  if (getTaskLoading) {
    return <FdProgress />;
  }

  return (
    <FdTable
      toolbarSettings={{
        title: usageDetail ? taskName : 'All Challenges',
        filterButton: true,
        searchBox: true,
      }}
      rows={rows || []}
      columns={usageDetail ? usageDetailcolumns : usageColumns}
      tablePageSize={10}
      onRowClick={onRowClick}
      rowClickable
      gridId="org-challenges-usages"
    />
  );
};
ChallengeUsageTable.defaultProps = {
  onRowClick: () => {},
  rows: [],
  taskName: '',
  getTaskLoading: false,
  usageDetail: false,
};

ChallengeUsageTable.propTypes = {
  onRowClick: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  taskName: PropTypes.string,
  getTaskLoading: PropTypes.bool,
  usageDetail: PropTypes.bool,
};

export default ChallengeUsageTable;
