import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { FdCard, FdRadioGroup, FdDelayed } from '@fifthdomain/fe-shared';
import { TEMPLATE_TYPES } from '../../../constants';
import FdTextView from './FdTextView';
import { capitalize } from '../../../shared/utils/stringUtils';

const TemplateType = ({ editMode, templateType }) => {
  const { control } = useFormContext();

  const Wrapper = editMode ? Box : FdCard;
  return (
    <Wrapper
      heading={`${capitalize(templateType)} Type`}
      subHeading={`Select the ${templateType} type for this template. Note that you cannot change this after creation of this template.`}
    >
      <Box display="flex" flexDirection="column">
        <Controller
          control={control}
          name="templateType"
          render={({
            field: { ref, value: fieldValue, ...rest },
            fieldState: { error },
          }) =>
            editMode ? (
              <FdTextView
                my={2}
                label={`${capitalize(templateType)} Type`}
                value={fieldValue?.value}
              />
            ) : (
              <FdDelayed>
                <Box mt={1} mb={1} ml={1}>
                  <FdRadioGroup
                    id="templateType"
                    label={`${capitalize(templateType)} Type`}
                    options={[
                      TEMPLATE_TYPES.INDIVIDUAL_BASED,
                      TEMPLATE_TYPES.TEAM_BASED,
                    ]}
                    defaultValue={fieldValue?.value}
                    fullWidth
                    error={error && error.message}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              </FdDelayed>
            )
          }
        />
      </Box>
    </Wrapper>
  );
};

TemplateType.propTypes = {
  editMode: PropTypes.bool,
  templateType: PropTypes.string.isRequired,
};

TemplateType.defaultProps = {
  editMode: false,
};

export default TemplateType;
