import Yup from '../Common';

export const initialValues = {
  templateType: '',
  name: '',
  description: '',
  hours: 0,
  minutes: 0,
  duration: '',
  story: '',
  tasks: [],
  preMessage: '',
  postMessage: '',
};

export const validationSchema = Yup.object().shape({
  templateType: Yup.object()
    .shape({ id: Yup.string(), value: Yup.string() })
    .nullable()
    .required('Please select an option'),
  name: Yup.string()
    .required('Please enter a template name')
    .max(150, 'Template name must be 150 characters or less')
    .noWhitespace('Enter a valid template name'),
  description: Yup.string(),
  hours: Yup.number()
    .min(0, 'Hours should be a whole number')
    .integer('Hours should be a whole number')
    .typeError('Hours should be a whole number'),
  minutes: Yup.number()
    .min(0, 'Minutes should be a whole number')
    .integer('Minutes should be a whole number')
    .typeError('Minutes should be a whole number'),
  duration: Yup.string().when(['hours', 'minutes'], {
    is: (hours, minutes) => Number(hours) === 0 && Number(minutes) === 0,
    then: Yup.string().required('Both hours and minutes cannot be zero'),
    otherwise: Yup.string().nullable(),
  }),
  story: Yup.string(),
  tasks: Yup.array().min(1),
  preMessage: Yup.string()
    .required('Please enter a pre-template message')
    .noWhitespace('Please enter a valid pre-template message'),
  postMessage: Yup.string()
    .required('Please enter a post-template message')
    .noWhitespace('Please enter a valid post-template message'),
});
