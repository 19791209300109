import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '115px',
    marginRight: '16px',
    border: `1px solid ${theme.palette.background.border}`,
    '&:hover, &:focus': {
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: theme.palette.background.paper,
    borderTop: `4px solid ${theme.palette.primary.main}`,
  },
  noSelected: {
    borderTop: `1px solid ${theme.palette.background.border}`,
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));
const ContentTabs = ({ data }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  return (
    <>
      <Box display="flex" justifyContent="space-between" my={2}>
        {data.map((d) => (
          <Box
            key={d.index}
            className={
              d.index === index
                ? `${classes.root} ${classes.selected}`
                : `${classes.root} ${classes.noSelected}`
            }
            onClick={() => {
              setIndex(d.index);
            }}
          >
            <Box className={classes.flex}>
              <FdTypography
                variant="h3"
                color={d.index === index ? 'primary' : 'secondary'}
                data-cy="tab-heading"
              >
                {d.heading}
              </FdTypography>
              <FdTypography
                variant="captiontext1"
                color={d.index === index ? 'primary' : 'secondary'}
                data-cy="tab-sub-heading"
              >
                {d.subHeading}
              </FdTypography>
            </Box>
          </Box>
        ))}
      </Box>

      <Box data-cy="tab-data">{data[index].data}</Box>
    </>
  );
};

ContentTabs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subHeading: PropTypes.string,
      index: PropTypes.number,
      data: PropTypes.element,
    }).isRequired,
  ).isRequired,
};

export default ContentTabs;
