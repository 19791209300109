import { USER_PERMISSION_TYPE } from '../../constants';

// usage - userHasPermission(user from context, USER_PERMISSION_TYPE.MANAGE_GROUPS)
const userHasPermission = (user, permission) => {
  const permissionList =
    user?.signInUserSession?.accessToken?.payload?.['cognito:groups'] || [];
  switch (permission) {
    case USER_PERMISSION_TYPE.FD_ADMIN: {
      return permissionList.includes('fd-admin');
    }
    default:
      return false;
  }
};

export { userHasPermission };
