import React, { useEffect } from 'react';
import { StylesProvider, createGenerateClassName } from '@material-ui/core';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  setShowNav,
  AppBasePageProvider,
  ReactQueryProvider,
} from '@fifthdomain/fe-shared';
import { AuthProvider } from '@fifthdomain/auth';
import GraphQlClient from './shared/GraphQlClient';
import OrganisationRoute from './routes/OrganisationRoute';
import ContentCatalogueRoute from './routes/ContentCatalogueRoute';
import ManageOrgs from './pages/ManageOrgs';
import CreateOrg from './pages/CreateOrg';
import ViewOrg from './pages/ViewOrg';
import ContentCatalogue from './pages/ContentCatalogue';
import AppThemeProvider from './providers/AppThemeProvider';
import ContentLeaseCatalogue from './pages/ContentLeaseCatalogue';
import ListTemplates from './pages/ListTemplates';
import CreateTemplate from './pages/CreateTemplate';
import ViewTemplate from './pages/ViewTemplate';

const generateClassName = createGenerateClassName({
  productionPrefix: 'organisation',
  seed: 'organisation',
});

const App = () => {
  useEffect(() => {
    setShowNav(true);
  });

  return (
    <GraphQlClient>
      <AuthProvider>
        <ReactQueryProvider>
          <AppThemeProvider>
            <StylesProvider generateClassName={generateClassName}>
              <>
                <ToastContainer />
                <AppBasePageProvider>
                  <BrowserRouter>
                    <Switch>
                      <OrganisationRoute
                        path="/org"
                        component={ManageOrgs}
                        exact
                      />
                      <OrganisationRoute
                        path="/org/create"
                        component={CreateOrg}
                      />
                      <OrganisationRoute
                        path="/org/view/:orgId"
                        component={ViewOrg}
                      />
                      <OrganisationRoute
                        path="/org/content-library"
                        component={ContentCatalogue}
                        exact
                      />
                      <OrganisationRoute
                        path="/org/template-library"
                        component={ListTemplates}
                        exact
                      />
                      <OrganisationRoute
                        path="/org/template-library/create/:templateType"
                        component={CreateTemplate}
                      />
                      <OrganisationRoute
                        path="/org/template-library/view/:templateType/:templateId"
                        component={ViewTemplate}
                      />
                      <ContentCatalogueRoute
                        path="/org/content-lease-catalogue"
                        component={ContentLeaseCatalogue}
                        exact
                      />
                      <Redirect to="/" />
                    </Switch>
                  </BrowserRouter>
                </AppBasePageProvider>
              </>
            </StylesProvider>
          </AppThemeProvider>
        </ReactQueryProvider>
      </AuthProvider>
    </GraphQlClient>
  );
};

export default App;
