export const USER_PERMISSION_TYPE = Object.freeze({
  FD_ADMIN: 'fd-admin',
});

export const THEMES = Object.freeze([
  { value: 'LIGHT', label: 'Light Theme' },
  { value: 'RETRO', label: 'Retro Theme' },
]);

export const PRICING_TIERS = Object.freeze([
  { value: 'STARTER', label: 'Starter' },
  { value: 'ADVANCED', label: 'Advanced' },
  { value: 'CUSTOM', label: 'Custom' },
]);

export const MANAGER_PERMISSIONS = {
  CREATE: 'Create Content',
  REVIEW_CONTENT: 'Review Content',
  MANAGE_CONTENT: 'Manage Content',
  MANAGE_EVENTS: 'Manage Events',
  MANAGE_USERS: 'Manage Users',
  MANAGE_GROUPS: 'Manage Groups',
  MANAGE_TAGS: 'Manage Tags',
  VIEW_INSIGHTS: 'View Insights',
  VIEW_BILLING: 'View Billing',
};

export const MANAGER_DEFAULT_PERMISSIONS = [
  'Participate',
  'Manage Profile',
  'View Users',
];

// amount in AUD
export const CONTENT_LEASE_CATALOG_PRICE = {
  staticContent: 165,
  labsContent: 165,
  containerContent: 165,
};

export const SPECIALTY_COLOR = {
  Intelligence: 'rgba(163, 40, 237, 1)',
  Protection: 'rgba(78, 78, 255, 1)',
  Detection: 'rgba(200, 73, 164, 1)',
  Investigation: 'rgba(62, 179, 214, 1)',
  Penetration: 'rgba(151, 177, 255, 1)',
  Engineering: 'rgba(164, 184, 209, 1)',
};

export const PROFICIENCY = {
  Novice: { color: 'rgba(118, 209, 165, 1)', range: '1-2', level: 1 },
  'Advanced Beginner': {
    color: 'rgba(151, 180, 255, 1)',
    range: '3-4',
    level: 2,
  },
  Competent: { color: 'rgba(246, 185, 113, 1)', range: '5-6', level: 3 },
  Proficient: { color: 'rgba(227, 128, 187, 1)', range: '7-8', level: 4 },
  Expert: { color: 'rgba(213, 83, 83, 1)', range: '9-10', level: 5 },
};

export const TEMPLATE_TYPES = {
  INDIVIDUAL_BASED: 'Individual-Based',
  TEAM_BASED: 'Team-Based',
};

export const TAG_COLOUR = [
  {
    backgroundColor: '#FFE082',
    value: 'YELLOW',
  },
  {
    backgroundColor: '#FFAB91',
    value: 'ORANGE',
  },
  {
    backgroundColor: '#F48FB1',
    value: 'PINK',
  },
  {
    backgroundColor: '#CE93D8',
    value: 'PURPLE',
  },
  {
    backgroundColor: '#40C4FF',
    value: 'BLUE',
  },
  {
    backgroundColor: '#80CBC4',
    value: 'TEAL',
  },
];

export const PROFICIENCY_LEVELS = {
  1: 'Novice',
  2: 'Advanced Beginner',
  3: 'Competent',
  4: 'Proficient',
  5: 'Expert',
};
