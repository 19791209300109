import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdCard, FdMarkdownEditor } from '@fifthdomain/fe-shared';

const PreMessage = ({ templateType, heading }) => {
  const { control } = useFormContext();

  return (
    <FdCard
      heading={heading || `Pre-${templateType} Message`}
      subHeading={`Enter a pre-${templateType} message that will be displayed to all organisation participants before and during their ${templateType}. `}
    >
      <Box mt={0}>
        <Controller
          control={control}
          name="preMessage"
          render={({ field, fieldState: { error } }) => (
            <Box mb={2}>
              <FdMarkdownEditor
                id="preMessage"
                name="preMessage"
                markdown={field.value}
                setMarkdown={field.onChange}
                label="Message"
                required
                error={error}
                errorText={error && error.message}
                {...field}
              />
            </Box>
          )}
        />
      </Box>
    </FdCard>
  );
};

PreMessage.propTypes = {
  templateType: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  heading: PropTypes.string,
};

PreMessage.defaultProps = {
  heading: '',
};

export default PreMessage;
