import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { Box } from '@material-ui/core';

const BarNivo = ({
  data,
  keys,
  indexBy,
  colors,
  yAxisLegend,
  axisBottom,
  enableLabel,
  ...props
}) => {
  const customTheme = {
    tooltip: {
      container: {
        fontFamily: 'Montserrat, sans-serif',
      },
    },
  };
  return (
    <Box data-cy="line-chart" height="100%">
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{
          top: 50,
          right: 130,
          bottom: 50,
          left: 150,
        }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={colors}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={axisBottom}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -30,
          legendPosition: 'middle',
          tickValues: 5,
          legend: yAxisLegend,
          legendOffset: -50,
        }}
        gridYValues={5}
        enableLabel={enableLabel}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        animate
        motionStiffness={90}
        motionDamping={15}
        theme={customTheme}
        {...props}
      />
    </Box>
  );
};

BarNivo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  indexBy: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  yAxisLegend: PropTypes.string,
  axisBottom: PropTypes.shape({}),
  enableLabel: PropTypes.bool,
};

BarNivo.defaultProps = {
  colors: [],
  yAxisLegend: '',
  axisBottom: null,
  enableLabel: true,
};

export default BarNivo;
