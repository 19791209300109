import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link, makeStyles } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';

const useStyles = makeStyles(() => ({
  urlLink: {
    '& a': {
      color: '#1976D2 !important',
    },
  },
}));
function FileAttachment({ logoKey, url, onClickAttachment }) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box mt={0.5} />
      <FdTypography
        variant="body1"
        className={classes.urlLink}
        data-cy="download-link"
      >
        <Link
          id={logoKey}
          key={logoKey}
          href={url}
          onClick={() => onClickAttachment?.()}
          target="_blank"
        >
          {logoKey}
        </Link>
      </FdTypography>
    </Box>
  );
}

FileAttachment.propTypes = {
  logoKey: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClickAttachment: PropTypes.func,
};

FileAttachment.defaultProps = {
  onClickAttachment: undefined,
};

export default FileAttachment;
