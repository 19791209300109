import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Link, IconButton } from '@material-ui/core';
import {
  FdTypography,
  FdMultiChipTextField,
  FdTooltip,
  FdIcons,
  FdCheckbox,
  InformationBox,
  USER_PERMISSIONS_EXPLAINER,
  MANAGER_ROLES_PERMISSIONS_GUIDE,
  PERMISSIONS_AFFILIATED_DISPLAY,
  FdAlert,
} from '@fifthdomain/fe-shared';
import { snakeCaseToTitleCase } from '../../shared/utils/stringUtils';
import { arrayToObject } from '../../shared/utils/objectUtils';

const InviteUsers = ({
  addUsers,
  emails,
  setEmails,
  accountOwner,
  setAccountOwner,
  userPermissions,
  setUserPermissions,
  error,
}) => {
  const { InfoOutlined } = FdIcons;

  return (
    <Box display="flex" flexDirection="column">
      <FdAlert
        variant="info"
        message="Users invited to join the platform as part of this organisation will receive an email requesting their affiliation. Once they accept affiliation, they will be granted the permissions listed below."
      />

      {addUsers && (
        <Box mt={2}>
          <FdTypography variant="subtitle1">
            Invitee Email Addresses
          </FdTypography>
          <FdTypography variant="captiontext1" color="secondary">
            Type or paste in email addresses of those who need to be affiliated
            to this organisation below.
          </FdTypography>

          <FdMultiChipTextField
            id="Emails"
            width="99%"
            height="130px"
            label=""
            placeholder="Emails"
            required
            values={emails}
            setValues={setEmails}
            data-cy="emails-selector"
          />
          {error && (
            <Box display="flex">
              <InformationBox variant="error" status="" message={error} />
            </Box>
          )}
        </Box>
      )}
      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Box className="flex items-center gap-x-2">
          <FdTypography variant="subtitle1">
            Assign Manager Permissions
          </FdTypography>
          <FdTypography variant="captiontext1" color="secondary">
            (optional)
          </FdTypography>
        </Box>
        <FdTypography variant="captiontext1" color="secondary">
          Read the{' '}
          <Link
            rel="noreferrer"
            target="_blank"
            href={USER_PERMISSIONS_EXPLAINER}
          >
            User Permissions Explainer
          </Link>{' '}
          for in-depth insights on permissions on FifthDomain platform. You can
          also refer the{' '}
          <Link
            rel="noreferrer"
            target="_blank"
            href={MANAGER_ROLES_PERMISSIONS_GUIDE}
          >
            Manager Roles Permission Guide
          </Link>{' '}
          for assigning appropriate permissions to suit specific use cases.
        </FdTypography>
      </Box>
      <Box mt={1} mb={2} className="flex flex-col">
        <FdCheckbox
          onChange={setUserPermissions}
          name="Permissions"
          options={arrayToObject(
            Object.keys(PERMISSIONS_AFFILIATED_DISPLAY).map((key) => ({
              [PERMISSIONS_AFFILIATED_DISPLAY[key]]: snakeCaseToTitleCase(
                PERMISSIONS_AFFILIATED_DISPLAY[key] === 'CREATE'
                  ? 'Create Content'
                  : PERMISSIONS_AFFILIATED_DISPLAY[key],
              ),
            })) || [],
          )}
          multiple
          defaultSelected={userPermissions}
        />
        <Divider />
        <FdCheckbox
          label={
            <Box display="flex" alignItems="center">
              <FdTypography variant="body1">Account Owner</FdTypography>
              <FdTooltip title="Users granted the 'Account Owner' permission possess protected access within the organisation. Their affiliation cannot be revoked, and their permissions cannot be modified by any other users in the organisation.">
                <IconButton size="small" style={{ marginLeft: '5px' }}>
                  <InfoOutlined />
                </IconButton>
              </FdTooltip>
            </Box>
          }
          checked={accountOwner}
          onChange={(v) => {
            setAccountOwner(v);
          }}
        />
      </Box>
    </Box>
  );
};

InviteUsers.propTypes = {
  addUsers: PropTypes.bool,
  emails: PropTypes.array,
  setEmails: PropTypes.func,
  accountOwner: PropTypes.string,
  setAccountOwner: PropTypes.func,
  userPermissions: PropTypes.array,
  setUserPermissions: PropTypes.func,
  error: PropTypes.string,
};

InviteUsers.defaultProps = {
  addUsers: false,
  emails: [],
  setEmails: () => {},
  accountOwner: null,
  setAccountOwner: () => {},
  userPermissions: [],
  setUserPermissions: () => {},
  error: null,
};

export default InviteUsers;
