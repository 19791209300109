import React from 'react';
import { Box } from '@material-ui/core';
import {
  FdCard,
  FdTextField,
  FdRadioGroup,
  FdTypography,
} from '@fifthdomain/fe-shared';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { PRICING_TIERS } from '../../constants';

const OrgDetails = ({ editMode }) => {
  const { control, setValue } = useFormContext();

  return (
    <FdCard
      heading={
        <Box display="flex" justifyContent="space-between">
          Details
          {editMode}
        </Box>
      }
      subHeading="Enter the organisation details."
    >
      <Box>
        <Controller
          control={control}
          name="orgName"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id="orgName"
                label="Name"
                required
                fullWidth
                error={error}
                placeholder="Name be more 150 characters or less"
                helperText={error && error.message}
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
      </Box>
      <Box>
        <FdTypography variant="subtitle1" fontWeight="medium">
          Pricing Tier
        </FdTypography>
        <FdTypography variant="body2" color="secondary">
          Select a pricing tier for this organisation. The pricing tier selected
          will determine the set of features that users in this organisation
          will have access to.
        </FdTypography>
        <Controller
          control={control}
          name="pricingTier"
          render={({ field: { value, ...rest }, fieldState: { error } }) => (
            <Box mt={1} ml={1}>
              <FdRadioGroup
                {...rest}
                id="pricingTier"
                options={PRICING_TIERS.map((tier) => tier.label)}
                defaultValue={value}
                onChange={(tier) => setValue('pricingTier', tier.value)}
                error={!value && error && error.message}
                fullWidth
                blockquote={false}
              />
            </Box>
          )}
        />
      </Box>
    </FdCard>
  );
};

OrgDetails.propTypes = {
  editMode: PropTypes.element,
};
OrgDetails.defaultProps = {
  editMode: null,
};

export default OrgDetails;
