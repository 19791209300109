import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';
import { hoursToMinutes } from 'date-fns/esm';
import { capitalize } from '../../shared/utils/stringUtils';
import { productStyle } from '../../shared/utils/layout';

const useStyles = makeStyles(() => ({
  cellHeading: {
    lineHeight: '28px',
  },
}));

const ContentInfoCell = ({ values }) => {
  const classes = useStyles();
  const _duration =
    hoursToMinutes(Number(values?.hours)) + Number(values?.minutes);
  return (
    <Box>
      <Box className={classes.cellHeading}>{values?.name || values?.name}</Box>
      <Box display="flex" flexDirection="row">
        <Box pr={2}>
          {values?.teamBased ? (
            <FdTypography
              variant="captiontext2"
              style={productStyle(values?.participantEventType, 'chip')}
            >
              {`Team ${capitalize(values?.participantEventType)}`}
            </FdTypography>
          ) : values?.courseModules ? (
            <FdTypography
              variant="captiontext2"
              style={productStyle('COURSE', 'chip')}
            >
              {` ${values?.courseModules?.items?.length} Modules`}
            </FdTypography>
          ) : (
            <FdTypography
              variant="captiontext2"
              style={productStyle(values?.participantEventType, 'chip')}
            >
              {`Solo ${capitalize(values?.participantEventType)}`}
            </FdTypography>
          )}
        </Box>
        <Box>
          {values?.participantEventType === 'ASSESSMENT' && (
            <FdTypography
              variant="captiontext2"
              style={productStyle('ASSESSMENT', 'chip')}
            >
              {` Duration :${_duration} min`}
            </FdTypography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ContentInfoCell.propTypes = {
  values: PropTypes.objectOf({
    difficulty: PropTypes.string,
    name: PropTypes.string,
    estimatedSolveTime: PropTypes.string,
  }).isRequired,
};

export default ContentInfoCell;
