import _ from 'lodash';

const specialityOrder = [
  'Intelligence',
  'Protection',
  'Detection',
  'Investigation',
  'Engineering',
  'Penetration',
];

export const getSpecialtyLegends = ({ specialtySkills }) => {
  const specialtySkillsMap = _(specialtySkills)
    .groupBy('specialty.id')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items,
    }))
    .sort(
      (a, b) =>
        specialityOrder.indexOf(a?.specialtyName) -
        specialityOrder.indexOf(b?.specialtyName),
    )
    .value();

  const skills = specialtySkills?.map((s) => ({
    skillName: s.name,
    alias: s.alias,
    specialtyName: s.specialty.name,
  }));

  const skillsSorted = skills
    ?.sort(
      (a, b) =>
        specialityOrder.indexOf(a?.specialtyName) -
        specialityOrder.indexOf(b?.specialtyName),
    )
    ?.map((s) => ({
      name: s.skillName,
      alias: s.alias,
    }));

  const skillsCount = skillsSorted?.length || 0;
  const specialtyLegends = specialtySkillsMap?.map((ss) => ({
    specialty: ss.specialtyName,
    width: `${Math.round((ss.items.length / skillsCount) * 100)}%`,
  }));

  return { specialtyLegends, skillsSorted };
};
