import React, { useEffect, useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { AuthContext } from '@fifthdomain/fe-shared';
import { USER_PERMISSION_TYPE } from '../constants';
import { userHasPermission } from '../shared/utils/getUsersPermissions';

const OrganisationRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);

  const history = useHistory();

  const fdAdminPermission =
    (user && userHasPermission(user, USER_PERMISSION_TYPE.FD_ADMIN)) || false;

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (userData) {
        setUser(userData);
      })
      .catch((err) => {
        console.log('error: ', err);
        history.push('/');
      });
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && !fdAdminPermission) {
          return <Redirect to="/" />;
        }

        if (user && fdAdminPermission) {
          return <Component {...rest} {...props} />;
        }

        return null;
      }}
    />
  );
};

OrganisationRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default OrganisationRoute;
